var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "report-menu"
  }, [_c("div", {
    staticClass: "operator-row"
  }, [_c("el-form", {
    attrs: {
      inline: true,
      model: _vm.searchInfo
    }
  }, [_c("div", {
    staticClass: "operator-field-group"
  }, [_c("div", {
    staticClass: "operator-field-group",
    staticStyle: {
      flex: "auto"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("user_group.userName")
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: _vm.$t("user_group.userName"),
      clearable: ""
    },
    model: {
      value: _vm.searchInfo.userName,
      callback: function callback($$v) {
        _vm.$set(_vm.searchInfo, "userName", $$v);
      },
      expression: "searchInfo.userName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.$t("user_group.dealerName")
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: _vm.$t("user_group.dealerName"),
      clearable: ""
    },
    model: {
      value: _vm.searchInfo.dealerName,
      callback: function callback($$v) {
        _vm.$set(_vm.searchInfo, "dealerName", $$v);
      },
      expression: "searchInfo.dealerName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.$t("user_group.role"),
      prop: "reportReportTool"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: _vm.$t("user_group.role")
    },
    model: {
      value: _vm.searchInfo.role,
      callback: function callback($$v) {
        _vm.$set(_vm.searchInfo, "role", $$v);
      },
      expression: "searchInfo.role"
    }
  }, _vm._l(_vm.userGroups, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.paramName,
        value: item.paramName
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "operator-field-group",
    staticStyle: {
      "min-width": "80px"
    }
  }, [_c("el-form-item", [_c("el-button", {
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.fetch
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetch.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.search")) + "\n            ")]), _vm._v(" "), _c("el-button", {
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.addUserGroup
    }
  }, [_vm._v(_vm._s(_vm.$t("common.add")) + "\n            ")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.onDelete
    }
  }, [_vm._v(_vm._s(_vm.$t("common.delete")))])], 1)], 1)])])], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "multipleTable",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      "tooltip-effect": "dark",
      "row-key": "ID"
    },
    on: {
      "selection-change": _vm.handleSelectionChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      width: "55"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "User ID",
      width: "120",
      sortable: "",
      prop: "userId"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("user_group.userName"),
      sortable: "",
      prop: "userName",
      "min-width": "110"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("user_group.role"),
      sortable: "",
      prop: "role",
      width: "110"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("user_group.dealerCode"),
      sortable: "",
      prop: "dealerCode",
      "min-width": "110",
      formatter: _vm.showDealerCodeNullValue
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("user_group.dealerName"),
      sortable: "",
      prop: "dealerName",
      width: "300",
      formatter: _vm.showDealerNameNullValue
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("user_group.dealerGroup"),
      sortable: "",
      prop: "dealerGroupName",
      "min-width": "110",
      formatter: _vm.showDealerGroupNullValue
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("user_group.region"),
      sortable: "",
      prop: "region",
      "min-width": "110",
      formatter: _vm.showRegionNullValue
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("user_group.subRegion"),
      sortable: "",
      prop: "subRegion",
      "min-width": "110",
      formatter: _vm.showSubRegionNullValue
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("common.operation")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            size: "mini",
            type: "primary",
            icon: "el-icon-edit",
            circle: ""
          },
          on: {
            click: function click($event) {
              return _vm.initUserGroup(scope.row);
            }
          }
        }), _vm._v(" "), _c("el-popconfirm", {
          attrs: {
            "confirm-button-text": "Yes",
            "cancel-button-text": "No",
            icon: "el-icon-info",
            "icon-color": "red",
            title: "确定删除吗？"
          },
          on: {
            confirm: function confirm($event) {
              return _vm.deleteUserGroup(scope.row);
            }
          }
        }, [_c("el-button", {
          attrs: {
            slot: "reference",
            size: "mini",
            type: "danger",
            icon: "el-icon-delete",
            circle: ""
          },
          slot: "reference"
        })], 1)];
      }
    }])
  })], 1), _vm._v(" "), _c("el-pagination", {
    style: {
      float: "right",
      padding: "20px"
    },
    attrs: {
      "current-page": _vm.page,
      "page-size": _vm.pageSize,
      "page-sizes": [10, 30, 50, 100],
      total: _vm.total,
      layout: "total, sizes, prev, pager, next, jumper"
    },
    on: {
      "current-change": _vm.handleCurrentChange,
      "size-change": _vm.handleSizeChange
    }
  }), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.flag,
      title: _vm.title
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.flag = $event;
      }
    }
  }, [_c("el-form", {
    ref: "userGroupForm",
    attrs: {
      model: _vm.groupInfo,
      rules: _vm.userRoles
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("user_group.userName"),
      "label-width": "100px",
      prop: "userName"
    }
  }, [_c("el-select", {
    staticStyle: {
      display: "block",
      width: "220px"
    },
    attrs: {
      clearable: "",
      filterable: "",
      remote: "",
      "remote-method": _vm.remoteMethod
    },
    on: {
      change: function change($event) {
        return _vm.selectSubjectItem($event);
      },
      "visible-change": _vm.getAllUserFunc
    },
    model: {
      value: _vm.groupInfo.userName,
      callback: function callback($$v) {
        _vm.$set(_vm.groupInfo, "userName", $$v);
      },
      expression: "groupInfo.userName"
    }
  }, _vm._l(_vm.dataItems, function (item) {
    return _c("el-option", {
      key: item.ID,
      attrs: {
        label: item.userName,
        value: {
          userId: item.ID,
          userName: item.userName
        }
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("user_group.role"),
      "label-width": "100px",
      prop: "role"
    }
  }, [_c("el-select", {
    staticStyle: {
      display: "block",
      width: "220px"
    },
    attrs: {
      clearable: ""
    },
    on: {
      change: function change($event) {
        return _vm.selectRoleSubjectItem($event);
      }
    },
    model: {
      value: _vm.groupInfo.role,
      callback: function callback($$v) {
        _vm.$set(_vm.groupInfo, "role", $$v);
      },
      expression: "groupInfo.role"
    }
  }, _vm._l(_vm.userGroups, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.paramName,
        value: item.paramName
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_vm.showDealerGroup ? _c("el-form-item", {
    attrs: {
      label: _vm.$t("user_group.dealerGroup"),
      "label-width": "110px",
      prop: "dealerGroupName",
      rules: {
        required: this.showDealerGroup,
        trigger: "change",
        message: "经销商组名称不能为空"
      }
    }
  }, [_c("el-select", {
    staticStyle: {
      display: "block",
      width: "220px"
    },
    attrs: {
      filterable: "",
      remote: "",
      "remote-method": _vm.remoteDealerGroupMethod,
      clearable: ""
    },
    on: {
      change: _vm.changeDealerGroup,
      "visible-change": _vm.getAllDealerGroup
    },
    model: {
      value: _vm.groupInfo.dealerGroupName,
      callback: function callback($$v) {
        _vm.$set(_vm.groupInfo, "dealerGroupName", $$v);
      },
      expression: "groupInfo.dealerGroupName"
    }
  }, _vm._l(_vm.dealerGroups, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.dealerGroupCode,
        value: item.dealerGroupCode
      }
    });
  }), 1)], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm.showDealerCode ? _c("el-form-item", {
    attrs: {
      label: _vm.$t("user_group.dealerCode"),
      "label-width": "110px",
      prop: "dealerCode",
      rules: {
        required: this.showDealerCode,
        trigger: "change",
        message: "经销商编码不能为空"
      }
    }
  }, [_c("el-select", {
    staticStyle: {
      display: "block",
      width: "220px"
    },
    attrs: {
      filterable: "",
      remote: "",
      "remote-method": _vm.remoteDealerCodeMethod,
      clearable: ""
    },
    on: {
      change: function change($event) {
        return _vm.getTheDealerName($event);
      },
      "visible-change": _vm.getAllDealerCode
    },
    model: {
      value: _vm.groupInfo.dealerCode,
      callback: function callback($$v) {
        _vm.$set(_vm.groupInfo, "dealerCode", $$v);
      },
      expression: "groupInfo.dealerCode"
    }
  }, _vm._l(_vm.dealerCodes, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.dealerCode,
        value: item.dealerCode
      }
    });
  }), 1)], 1) : _vm._e()], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm.showDealerName ? _c("el-form-item", {
    attrs: {
      label: _vm.$t("user_group.dealerName"),
      "label-width": "110px",
      prop: "dealerName",
      rules: {
        required: this.showDealerName,
        trigger: "change",
        message: "经销商名称不能为空"
      }
    }
  }, [_c("el-select", {
    staticStyle: {
      display: "block",
      width: "300px"
    },
    attrs: {
      filterable: "",
      remote: "",
      "remote-method": _vm.remoteDealerNameMethod,
      clearable: ""
    },
    on: {
      change: function change($event) {
        return _vm.getTheDealerCode($event);
      },
      "visible-change": _vm.getAllDealerName
    },
    model: {
      value: _vm.groupInfo.dealerName,
      callback: function callback($$v) {
        _vm.$set(_vm.groupInfo, "dealerName", $$v);
      },
      expression: "groupInfo.dealerName"
    }
  }, _vm._l(_vm.dealerNames, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.nameCn,
        value: item.nameCn
      }
    });
  }), 1)], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm.showRegion ? _c("el-form-item", {
    attrs: {
      label: _vm.$t("user_group.region"),
      "label-width": "110px",
      prop: "region",
      rules: {
        required: this.showRegion,
        trigger: "change",
        message: "大区不能为空"
      }
    }
  }, [_c("el-select", {
    staticStyle: {
      display: "block",
      width: "220px"
    },
    attrs: {
      filterable: "",
      remote: "",
      "remote-method": _vm.remoteRegionMethod,
      clearable: ""
    },
    on: {
      change: function change($event) {
        return _vm.getTheSubRegions($event);
      },
      "visible-change": _vm.getAllRegions
    },
    model: {
      value: _vm.groupInfo.region,
      callback: function callback($$v) {
        _vm.$set(_vm.groupInfo, "region", $$v);
      },
      expression: "groupInfo.region"
    }
  }, _vm._l(_vm.regions, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1)], 1) : _vm._e()], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm.showSubRegion ? _c("el-form-item", {
    attrs: {
      label: _vm.$t("user_group.subRegion"),
      "label-width": "110px",
      prop: "subRegion",
      rules: {
        required: this.showSubRegion,
        trigger: "change",
        message: "小区不能为空"
      }
    }
  }, [_c("el-select", {
    staticStyle: {
      display: "block",
      width: "300px"
    },
    attrs: {
      filterable: "",
      remote: "",
      "remote-method": _vm.remoteTheSubRegionMethod,
      clearable: ""
    },
    on: {
      change: _vm.changeSubRegion,
      "visible-change": function visibleChange($event) {
        return _vm.getTheSubRegions(_vm.groupInfo.region);
      }
    },
    model: {
      value: _vm.groupInfo.subRegion,
      callback: function callback($$v) {
        _vm.$set(_vm.groupInfo, "subRegion", $$v);
      },
      expression: "groupInfo.subRegion"
    }
  }, _vm._l(_vm.theSubRegions, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1)], 1) : _vm._e()], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      icon: "el-icon-refresh-right"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(_vm._s(_vm.$t("common.reset")))]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.saveUserGroup
    }
  }, [_vm._v(_vm._s(_vm.$t("common.save")))]), _vm._v(" "), _c("el-button", {
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v(_vm._s(_vm.$t("common.cancel")))])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };